<script>
  import SocketMySpace1A from "../../../socket/myspace";

  export default {
    props: ["claps", "showClaps", "payload", "openClaps"],
    data() {
      return {
        clap: false,
        timeOut: null,
      };
    },
    computed: {
      isLiked() {
        return this.claps.some((clap) => clap.user._id === this.$user._id) && !this.clap;
      },
      clapsNumber() {
        const claps = this.claps.reduce((acc, item) => acc + item.claps, 0);

        if (claps >= 1000) {
          return `${(claps / 1000).toFixed(1)}k`;
        }

        return claps;
      },
    },
    methods: {
      setLiked() {
        const socket = SocketMySpace1A.getSocket();

        socket.emit("setClapItem", this.payload);
        this.setClap();
      },
      setClap() {
        clearTimeout(this.timeOut);

        this.clap = true;
        this.timeOut = setTimeout(() => (this.clap = false), 2);
      },
      showClapsMethod() {
        if (!this.openClaps) {
          return;
        }

        this.openClaps(this.claps);
      },
    },
  };
</script>

<template>
  <section class="claps">
    <div class="claps__heartWrapper" @click.stop="setLiked">
      <div :class="['claps__heart', isLiked && 'claps__heart--liked']" />
    </div>
    <p :class="['claps__total', openClaps && 'claps__total--method']" @click.stop="showClapsMethod" v-if="showClaps">{{ clapsNumber }}</p>
  </section>
</template>

<style lang="scss" scoped>
  .claps {
    @include Flex(row, space-between);
    color: #000;
    &__heartWrapper {
      @include Flex(row);
      height: 40px;
      width: 30px;
      transition: 0.2s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
    }
    &__heart {
      height: 70px;
      width: 70px;
      background: url("../../../assets/img/Clap__.png");
      position: absolute;
      background-position: left;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      z-index: 1;
      &--liked {
        animation: like-anim 0.7s steps(28) forwards;
      }
    }
    &__total {
      padding: 0 $space-8;
      font-family: $newFont-regular;
      background: transparent;
      z-index: 5;
      &--method {
        cursor: pointer;
      }
    }
    @keyframes like-anim {
      to {
        background-position: right;
      }
    }
  }
</style>
